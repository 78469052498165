(function ($) {
  $(window).load(function () {
    // owl-carousel
    $(".owl-carousel:not(.slider)").owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: false,
    });

    // slider
    $(".slider").owlCarousel({
      items: 1,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      let headerOuterHeight = 0;
      $headerHeight.children().each(function () {
        headerOuterHeight += $(this).outerHeight();
      });
      console.error(headerOuterHeight);

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - headerOuterHeight - 125;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: newScrollTop,
      });
    }, 500);
  });

  $(document).ready(function () {
    // sticky
    require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 500);
      }
    });
  });
})(jQuery);

// nav-links

let navLinks = document.querySelectorAll(".navbar-nav .nav-link span");

navLinks.forEach((link) => {
  let spanContent = link.innerHTML.split("");
  link.innerHTML = "";
  spanContent.forEach((letter) => {
    let spanLetter = document.createElement("span");
    if (letter == " ") {
      spanLetter.innerHTML = "&nbsp;";
    } else {
      spanLetter.innerHTML = letter;
    }
    link.appendChild(spanLetter);
  });
  link.addEventListener("mouseover", () => {
    let letters = link.querySelectorAll("span");
    letters.forEach((l) => {
      l.style.transition = "0.35s ease";
      l.style.transform = `rotate(${Math.random() * 30 - 15}deg)`;
    });
  });
  link.addEventListener("mouseout", () => {
    let letters = link.querySelectorAll("span");
    letters.forEach((l) => {
      l.style.transform = `none`;
    });
  });
});

// notification active switch

let liItems = document.querySelectorAll(".notification-bar li");
if (liItems) {
  function activateItem(index) {
    if (index < liItems.length) {
      liItems[index].classList.add("active");

      setTimeout(() => {
        liItems[index].classList.remove("active");
        activateItem((index + 1) % liItems.length);
      }, 5000);
    }
  }

  activateItem(0);
}

// mobile header

const header = document.querySelector("header");

function toggleStickyClass() {
  if (window.scrollY > 0) {
    header.classList.add("sticky-mobile");
  } else {
    header.classList.remove("sticky-mobile");
  }
}

window.onscroll = () => {
  toggleStickyClass();
};

window.onload = () => {
  toggleStickyClass();
};

// language siteswitcher

let languages = document.querySelectorAll(".site-switcher .nav-link");
if (languages) {
  languages.forEach((lan) => {
    let lanArr = lan.innerHTML.split("");
    let newArr = [];

    for (let i = 0; i < lanArr.length; i++) {
      if (lanArr[i] != " " && lanArr[i] != "\n") {
        newArr.push(lanArr[i]);
      }
    }
    let lastTwo = newArr.slice(-2).join("");
    lan.innerHTML = lastTwo;
  });
}

// faq fix
let faqlinks = document.querySelectorAll(".faq-category a");
faqlinks.forEach((link) => {
  if (link.id) {
    link.id = "";
    link.href = "#";
  }
});
